<template>
  <Item :item="item" :parent="parent">
    <client-only>
      <is-admin>
        <div
          v-if="isAdminMode"
          :style="btnStyles"
          class="public-submit-btn"
          :class="{
            'public-btn--rounded': btnShape === 'rounded',
            'public-btn--rounded-full': btnShape === 'roundedFull',
            'public-btn--gradient': btnStyle === 'gradient',
          }"
        >
          <Editor
            :item-name="item.name"
            :content="content"
            @update="onEditorUpdate"
          />
        </div>
      </is-admin>
    </client-only>

    <is-guest>
      <button
        :style="btnStyles"
        type="submit"
        class="public-submit-btn"
        :class="{
          'public-btn--rounded': btnShape === 'rounded',
          'public-btn--rounded-full': btnShape === 'roundedFull',
          'public-btn--gradient': btnStyle === 'gradient',
        }"
        v-html="content"
      />
    </is-guest>
  </Item>
</template>

<script>
import { dispatch, get } from 'vuex-pathify'
import itemMixin from '@/mixins/item'

export default {
  name: 'SubmitBtn',

  components: {
    Item: () => import('@/components/Item'),
    Editor: () => import('@/components/Editor'),
  },

  mixins: [itemMixin],

  computed: {
    isAdminMode: get('isAdminMode'),
    btnShape: get('page/btnShape'),
    btnStyle: get('page/btnStyle'),

    btnStyles() {
      return {
        backgroundColor: 'var(--contrastColor)',
        color: 'var(--contrastOverlayColor)',
      }
    },

    content() {
      return this.item.content?.html
    },
  },

  methods: {
    onEditorUpdate(value) {
      dispatch('page/update', {
        mutation: 'UPDATE_ITEM_CONTENT',
        item: this.item,
        value,
      })
    },
  },
}
</script>

<style lang="postcss">
[data-item='Btn'] p {
  opacity: 1;
}

.public-submit-btn {
  @apply w-full justify-center h-full flex items-center px-6 py-3;
  font-size: 1.25rem;

  & * {
    @apply whitespace-nowrap;
  }
}

@screen md {
  .public-submit-btn {
    @apply px-8;
  }
}

@screen lg {
  .public-submit-btn {
    @apply px-10;
  }
}
</style>
