var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Item',{attrs:{"item":_vm.item,"parent":_vm.parent}},[_c('client-only',[_c('is-admin',[(_vm.isAdminMode)?_c('div',{staticClass:"public-submit-btn",class:{
          'public-btn--rounded': _vm.btnShape === 'rounded',
          'public-btn--rounded-full': _vm.btnShape === 'roundedFull',
          'public-btn--gradient': _vm.btnStyle === 'gradient',
        },style:(_vm.btnStyles)},[_c('Editor',{attrs:{"item-name":_vm.item.name,"content":_vm.content},on:{"update":_vm.onEditorUpdate}})],1):_vm._e()])],1),_vm._v(" "),_c('is-guest',[_c('button',{staticClass:"public-submit-btn",class:{
        'public-btn--rounded': _vm.btnShape === 'rounded',
        'public-btn--rounded-full': _vm.btnShape === 'roundedFull',
        'public-btn--gradient': _vm.btnStyle === 'gradient',
      },style:(_vm.btnStyles),attrs:{"type":"submit"},domProps:{"innerHTML":_vm._s(_vm.content)}})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }